

// @import "bootstrap/scss/functions";  // Only for v5


// Variables
//
// Copy settings from this file into the provided `_custom.scss` to override
// the Bootstrap defaults without modifying key, versioned files.


// Table of Contents
//
// Colors
// Options
// Spacing
// Body
// Links
// Grid breakpoints
// Grid containers
// Grid columns
// Fonts
// Components
// Tables
// Buttons
// Forms
// Dropdowns
// Z-index master list
// Navbar
// Navs
// Pagination
// Jumbotron
// Form states and alerts
// Cards
// Tooltips
// Popovers
// Badges
// Modals
// Alerts
// Progress bars
// List group
// Image thumbnails
// Figures
// Breadcrumbs
// Carousel
// Close
// Code

@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

// General variable structure
//
// Variable format should follow the `$component-modifier-state-property` order.


// Colors
//
// Grayscale and brand colors for use across Bootstrap.

$pink:                      #ff3853;
$orange:                    #fc6b00;
$yellow:                    #fce700;
$blue-light:                #0E74DA;
$blue:                      #0E74DA; // #3197fd; // #3399ff;
$blue-dark:                 #1c5b99; // #3399ff;
// $blue-dark:                 #303db8;
// $blue-dark:                 #353940;
$green:                     #33cccc;
$green-light:               #80c7c7;
$green-lighter:             #b9e1e1;

$black:                     #000000;
$gray-dark:                 #1f242b;
$gray:                      #636363;
$gray-help:                 #767676;
$gray-light:                #818a91;
$gray-lighter:              #eceeef;
$gray-lightest:             #f7f7f9;
$white:                     #ffffff;

$brand-primary:             $blue;
$brand-secondary:           $green;
$brand-success:             #5cb85c;
$brand-info:                #5bc0de;
$brand-warning:             #f0ad4e;
$brand-danger:              #d9534f;


// Easings

$ease-out-quart:            cubic-bezier(0.165, 0.84, 0.44, 1);


// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-rounded:            true;
$enable-shadows:            false;
$enable-gradients:          false;
$enable-transitions:        true;
$enable-hover-media-query:  false;
$enable-grid-classes:       true;
$enable-print-styles:       false;


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.

$spacer:                     1rem;
$spacer-x:                   $spacer;
$spacer-y:                   $spacer;
$spacers: (
  0: (
    x: 0,
    y: 0
  ),
  1: (
    x: ($spacer-x * .25),
    y: ($spacer-y * .25)
  ),
  2: (
    x: ($spacer-x * .5),
    y: ($spacer-y * .5)
  ),
  3: (
    x: $spacer-x,
    y: $spacer-y
  ),
  4: (
    x: ($spacer-x * 1.5),
    y: ($spacer-y * 1.5)
  ),
  5: (
    x: ($spacer-x * 3),
    y: ($spacer-y * 3)
  )
);
$border-width:               2px;


// Body
//
// Settings for the `<body>` element.

$body-bg:                    #fff;
$body-color:                 $gray-dark;


// Links
//
// Style anchor elements.

$link-color:                 $blue-dark;
$link-decoration:            none;
$link-hover-color:           $blue-dark;
$link-hover-decoration:      none;
$link-transition:            color .2s;


// Grid breakpoints
//
// Define the minimum and maximum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: 544px,
  // Medium screen / tablet
  md: 768px,
  // Large screen / desktop
  lg: 992px,
  // Extra large screen / wide desktop
  xl: 1280px
);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 576px,
  md: 720px,
  lg: 940px,
  xl: 1200px
);


// Grid columns
//
// Set the number of columns and specify the width of the gutters.
$gutters: null;

$grid-columns:               12;
$grid-gutter-width-base:     2.5rem; // 40px
$grid-gutter-width-xs:       1.5rem;
$grid-gutter-widths: (
  xs: $grid-gutter-width-xs,
  sm: $grid-gutter-width-base,
  md: $grid-gutter-width-base,
  lg: $grid-gutter-width-base,
  xl: $grid-gutter-width-base
);


// Min device height
//
// Define the minimum device height for full height components

$min-device-height:          568px;


// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-proxima-nova:   "Proxima Nova";
$font-family-sans-serif:     #{$font-family-proxima-nova}, "Helvetica Neue", Arial, sans-serif;
$font-family-base:           $font-family-sans-serif;

// Font weights
$font-weight-normal:         400;
$font-weight-semibold:       600;
$font-weight-bold:           700;

// Pixel value used to responsively scale all typography. Applied to the `<html>` element.
$font-size-root:             14px; // This doesn't appear to be used

// $font-size-base:             1.125rem; // 18px
$font-size-base:             0.8rem; // 12px
$font-size-lg:               1.75rem;  // 28px
$font-size-sm:               .9375rem; // 15px
$font-size-xs:               .8125rem; // 13px
$font-size-xxs:              .7rem;

$font-size-h1-xs:            2.25rem;
$font-size-h1-md:            3.5rem;
$font-size-h1-lg:            4.0625rem;

$font-size-h2-xs:            1.5rem;
$font-size-h2-md:            2.25rem;
$font-size-h2-lg:            2.25rem;

$font-size-h3-xs:            1.75rem;
$font-size-h3-md:            2.25rem;
$font-size-h3-lg:            2.25rem;

$font-size-h4-xs:            1.5rem;
$font-size-h4-md:            1.75rem;
$font-size-h4-lg:            1.75rem;

$font-size-h5-xs:            1rem;
$font-size-h5-md:            1.125rem;
$font-size-h5-lg:            1.125rem;

// $line-height-base:           1.67;
$line-height-base:           1.4;

$headings-margin-bottom:     ($spacer / 2); // $spacer * 1.5;
$headings-font-family:       inherit;
$headings-font-weight:       bold;
$headings-line-height:       1.3;
$headings-color:             inherit;

$lead-font-size:             1.25rem;
$lead-font-weight:           300;

$text-muted:                  $gray-light;

$abbr-border-color:           $gray-light;

$blockquote-color:            inherit;
$blockquote-font-size-xs:     $font-size-lg;
$blockquote-font-size-md:     2.25rem;
$blockquote-font-style:       italic;

$hr-border-color:             #cacaca;
$hr-border-width:             1px;


// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg:         (4 / 3);
$line-height-sm:         1.5;

$border-radius:          .25rem;
$border-radius-lg:       .3rem;
$border-radius-sm:       .2rem;

$component-active-color: #fff;
$component-active-bg:    $brand-primary;


// Blocks

$block-spacing-xs:       2rem; // 3.125rem;
$block-spacing-sm:       3.75rem;
$block-spacing-md:       5rem;
$block-small:            2rem;


// Side Menu

$side-menu-width:        300px;
$side-menu-padding:      30px 30px;
$side-menu-padding-sm:   80px 50px;
$side-menu-bg:           $gray-dark;
$side-menu-color:        #fff;


// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding:            .75rem;
$table-sm-cell-padding:         .3rem;

$table-bg:                      transparent;

$table-bg-hover:                $gray-lightest;
$table-bg-active:               rgba($brand-primary, .1);

$table-border-width:            $border-width;
$table-border-color:            $gray-lighter;

$table-responsive-width:        800px;


// Buttons
//
// For each of Bootstrap's buttons, define text, background and border color.

$btn-padding-x:                  2rem;
$btn-padding-y:                  .375rem;
$btn-line-height:                $line-height-base;
$btn-font-weight:                $font-weight-semibold;
$btn-box-shadow:                 inset 0 1px 0 rgba($white,.15), 0 1px 1px rgba($black,.075);
$btn-focus-box-shadow:           0 0 0 2px rgba($brand-primary, 1);
$btn-active-box-shadow:          inset 0 3px 5px rgba($black,.125);

$btn-default-color:              #fff;
$btn-default-bg:                 $gray-dark;
$btn-default-border:             $gray-dark;

$btn-primary-color:              #fff;
$btn-primary-bg:                 $blue-dark;
$btn-primary-border:             $btn-primary-bg;

$btn-secondary-color:            #fff;
$btn-secondary-bg:               $brand-secondary;
$btn-secondary-border:           $btn-secondary-bg;

$btn-link-disabled-color:        $gray-light;

$btn-padding-x-xs:               .8rem;
$btn-padding-y-xs:               $btn-padding-y;

$btn-padding-x-sm:               1.125rem;
$btn-padding-y-sm:               $btn-padding-y;

$btn-padding-x-lg:               1.25rem;
$btn-padding-y-lg:               .75rem;

$btn-padding-x-xl:               2.125rem;
$btn-padding-y-xl:               1.25rem;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:              $border-radius;
$btn-border-radius-lg:           $border-radius-lg;
$btn-border-radius-sm:           $border-radius-sm;

$btn-transition:                 color .2s, background-color .2s, box-shadow .2s, opacity .2s;


// Forms

$input-padding-x:                .875rem;
$input-padding-y:                .75rem;

$input-bg:                       #fff;
$input-bg-disabled:              $gray-lighter;

$input-color:                    $body-color;
$input-border-color:             #ccc;
$input-btn-border-width:         1px;
$input-box-shadow:               none;

$input-border-radius:            $border-radius;
$input-border-radius-lg:         $border-radius-lg;
$input-border-radius-sm:         $border-radius-sm;

$input-border-focus:             $brand-secondary;
$input-box-shadow-focus:         none;

// $input-color-placeholder:        #d0d0d0;
$input-color-placeholder:        #767676;

$input-padding-x-sm:             .75rem;
$input-padding-y-sm:             .375rem;

$input-padding-x-lg:             1rem;
$input-padding-y-lg:             .75rem;

$input-height:                   (($font-size-base * $line-height-base) + ($input-padding-y * 2));
$input-height-lg:                (($font-size-lg * $line-height-lg) + ($input-padding-y-lg * 2));
$input-height-sm:                (($font-size-sm * $line-height-sm) + ($input-padding-y-sm * 2));

$form-group-margin-bottom:       $spacer-y;

$custom-control-gutter:          2rem;
$custom-control-spacer-x:        0;
$custom-control-spacer-y:        .25rem;

$custom-control-indicator-size:       1.25rem;
$custom-control-indicator-margin-y:   (($line-height-base * 1rem) - $custom-control-indicator-size) / -2;
$custom-control-indicator-bg:         $gray-lighter;
$custom-control-indicator-bg-size:    75% 75%;
$custom-control-focus-indicator-box-shadow: none;

$custom-control-checked-indicator-color:      $white;
$custom-control-checked-indicator-bg:         $brand-primary;

$custom-toggle-gutter:                4rem;
$custom-toggle-indicator-size:        1.5rem;
$custom-toggle-radius:                $custom-toggle-indicator-size / 2;
$custom-toggle-bg:                    $custom-control-indicator-bg;
$custom-toggle-indicator-bg:          $white;
$custom-toggle-indicator-border:      #ccc; // $gray-lighter;
$custom-toggle-checked-bg:            $brand-secondary;
$custom-toggle-checked-indicator-border: $brand-secondary;
$custom-toggle-checked-icon:          none;

$custom-checkbox-radius:                $border-radius;
$custom-checkbox-bg:                    transparent;
$custom-checkbox-border:                #ccc; // $gray-lighter;
$custom-checkbox-checked-icon:          str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$custom-control-checked-indicator-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"), "#", "%23");
$custom-checkbox-checked-bg:            $brand-primary;
$custom-checkbox-checked-border:        $brand-primary;

$custom-radio-radius:                   50%;
$custom-radio-bg:                       transparent;
$custom-radio-border:                   #ccc; // $gray-lighter;
$custom-radio-indicator-bg:             fade-out($brand-secondary, 1); // $custom-control-indicator-bg;
$custom-radio-indicator-border:         transparent; // fade-out($brand-secondary, 1);
$custom-radio-checked-border:           $brand-secondary;
$custom-radio-checked-indicator-bg:     $brand-secondary; // fade-out($gray-lighter, 1);
$custom-radio-checked-icon:             none;

$custom-select-bg-size:                 20px 20px;
$custom-select-indicator-color:         $body-color;
$custom-select-indicator:               str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#{$custom-select-indicator-color}' d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z'/%3E%3C/svg%3E"), "#", "%23");
$custom-select-padding-x:               .125rem;

$custom-badge-radius:                   $border-radius;
$custom-badge-padding:                  .125rem .5rem;


// Form validation icons
$form-icon-success: none;
$form-icon-warning: none;
$form-icon-danger: none;


// Dropdowns

$dropdown-min-width:             9rem;
$dropdown-padding-y:             $border-radius;
$dropdown-margin-top:            5px;
$dropdown-bg:                    $gray-dark;
$dropdown-border-width:          0;
$dropdown-divider-bg:            $gray-lighter;
$dropdown-box-shadow:            0 .5rem 1rem rgba($black, .1);

$dropdown-link-color:            $white;
$dropdown-link-hover-color:      $white;
$dropdown-link-hover-bg:         rgba($white, .05);

$dropdown-link-active-color:     $white;
$dropdown-link-active-bg:        rgba($white, .1);

$dropdown-item-padding-y:        .125rem;
$dropdown-item-padding-x:        .5rem;

// $dropdown-header-color:          $gray-light;


// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-dropdown-backdrop:  990;
$zindex-navbar:             1000;
$zindex-dropdown:           1000;
$zindex-fixed:              1030;
$zindex-sticky:             1030;
$zindex-modal-backdrop:     1040;
$zindex-modal:              1050;
$zindex-popover:            1060;
$zindex-tooltip:            1070;
$zindex-spinner:            1080;


// Navbar

$navbar-border-radius:              $border-radius;
$navbar-padding-x:                  3.125rem;
$navbar-padding-y:                  0;
$navbar-height-xs:                  60px;
$navbar-height-sm:                  60px;

$navbar-toggler-padding-x:          0;
$navbar-toggler-padding-y:          0;

$navbar-light-color:                $gray;
$navbar-light-hover-color:          $gray;
$navbar-light-active-color:         $gray;
$navbar-light-disabled-color:       fade-out($gray, .5);
$navbar-light-toggler-bg:           none;

$navbar-dark-color:                 #fff;
$navbar-dark-hover-color:           #fff;
$navbar-dark-active-color:          #fff;
$navbar-dark-disabled-color:        fade-out(#fff, .5);
$navbar-dark-toggler-bg:            none;


// Navs

$nav-link-padding:                          .5em 1em;
$nav-link-hover-bg:                         $gray-lighter;

$nav-disabled-link-color:                   $gray-light;
$nav-disabled-link-hover-color:             $gray-light;

$nav-tabs-border-color:                     #ddd;
$nav-tabs-link-border-width:                $border-width;
$nav-tabs-link-hover-border-color:          $gray-lighter;
$nav-tabs-active-link-hover-bg:             $body-bg;
$nav-tabs-active-link-hover-color:          $gray;
$nav-tabs-active-link-hover-border-color:   #ddd;
$nav-tabs-justified-link-border-color:            #ddd;
$nav-tabs-justified-active-link-border-color:     $body-bg;

$nav-pills-border-radius:                   $border-radius;
$nav-pills-active-link-hover-bg:            $component-active-bg;
$nav-pills-active-link-hover-color:         $component-active-color;


// Cards

$card-deck-margin:         .5rem;


// Modals

$modal-dialog-margin:         0;
$modal-dialog-sm-up-margin-y: 0;

$modal-title-line-height:     $headings-line-height;

$modal-content-padding:          $grid-gutter-width-base $grid-gutter-width-base / 2;
$modal-content-padding-md:       $grid-gutter-width-base;
$modal-content-bg:               $white;
$modal-content-border-width:     0;
$modal-content-box-shadow:       0 10px 40px rgba(0,0,0,.2);

$modal-backdrop-bg:           $gray-dark;
$modal-backdrop-opacity:      .75;
$modal-header-border-color:   transparent;
$modal-footer-border-color:   transparent;
$modal-header-border-width:   0;
$modal-footer-border-width:   0;
$modal-header-padding:        0;
$modal-inner-padding:         0;

$modal-xxl:                   1200px;
$modal-xl:                    1000px;
$modal-lg:                    800px;
$modal-md:                    600px;
$modal-sm:                    300px;


@import "bootstrap/scss/variables";