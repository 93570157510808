@import "variables", "mixins";

.page-home {
    main {
        margin-top: -$navbar-height-xs;
    }

    .section-hero {
        position: relative;
        height: 100vh;
        min-height: $min-device-height;
        background-image: url('/ui/images/hero-bg.png');
        background-size: cover;
        background-position: center;

        .container,
        .row {
            height: 100%;
        }

        h1 {
            margin-bottom: 3.75rem;
        }
    }

    .section-pricing {
        padding-top: 10rem;

        h2 {
            margin-bottom: 4rem;
            color: $brand-primary;
        }
    }

    .apps-sample {
        margin-top: 10rem;
        margin-bottom: 4rem;
        max-width: 100%;

        dt {
            font-size: 1.5rem;
        }

        dd {
            font-size: 1rem;
        }
    }

    @include media-breakpoint-up(sm) {
        .apps-sample {
            margin-top: 3rem;
        }

        @media (min-height: $min-device-height) {
            main {
                margin-top: -$navbar-height-sm;
            }
        }
    }

    .section-connections {
        text-align: center;

        h2 {
            margin-bottom: 20px;
        }

        img {
            margin: 20px 0;
            max-height: 80px;
            max-width: 100%;
        }
    }

    #parents {
        margin-top: -$navbar-height-xs;
        padding-top: $navbar-height-xs;

        @include media-breakpoint-up(md) {
            @media (min-height: $min-device-height) {
                margin-top: -$navbar-height-sm;
                padding-top: $navbar-height-sm;
            }
        }
    }
}